import React from 'react';
import './App.css';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';  // <-- Import Framer Motion
import Header from './components/Header';
import VideoSection from './components/VideoSection';
import FeatureSection from './components/FeatureSection';
import FeatureFamily from './components/FeatureFamily';
import Footer from './components/Footer';
import Products from './components/Products';  // Import the Products component
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import PricingPage from './components/PricingPage';
import Signup from './components/Signup';
import Whitematter from '../src/components/whitematter-logo.png';
import Whitematterimage from './components/image (8) (1).png';
import Sign from './components/Sign';
import OtpVerification from './components/OtpVerification';
import Account from './components/account';
import Success from './components/Success';
import Cancel from './components/Cancel';
import PrivacyPolicy from './components/PrivacyPolicy';
import DownloadPage from './components/DownloadPage';
import LogoSection from './components/LogoSection';
import Section from './components/Section';
import CookiePopup from './components/CookiePopup';
import Trustmark from './components/trustmark';
import TrustmarkPricing from './components/TrustmarkPricing';
import WebsiteForm from './components/WebsiteForm';
import Newsletter from './components/Newsletter';
import HRConsulting from './components/hrconsulting';
import CyberSecurity from './components/cybersecurity';
import PartnerPortal from './components/Partnerportal';

function Home() {
  const navigate = useNavigate();  // useNavigate hook for programmatic navigation

  const handleLearnMoreClick = () => {
    navigate('/products', { state: { scrollToWorkSmarter: true } });  // Pass state with navigation
  };

  const handleTryForFreeClick = () => {
    window.open('https://bbk.qualtrics.com/jfe/form/SV_2oCi43Jz6oKlDYa', '_blank');
  };
  
  return (
    <main className="main-content tech-bg"> 
      {/* Helmet for SEO */}
      <Helmet>
        <title>Home - Erasys</title>
        <meta
          name="description"
          content="Whitematter by Erasys helps you optimize productivity and maintain mental well-being through AI-powered brain tracking and neurofeedback."
        />
      </Helmet>

      <div className="imagecontainer">
        <img src={Whitematter} className="Logomain" alt="Whitematter Logo" />
      </div>
    
      <motion.div
        className="content-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
      >
        {/* Left Content: slide in from left + fade */}
        <motion.div
          className="left-content"
          initial={{ x: -60, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.9, ease: 'easeOut' }}
        >
          <h1 className="gradient-text glow-text">
            Monitor Stress, Cognitive Fatigue, Productivity and more...
          </h1>
          <p className="tech-paragraph">
            Whitematter helps users to optimize <strong>productivity</strong> and maintain
            <strong> mental well-being</strong> <em>— no wearables needed.</em> With its
            <strong> AI-driven</strong> neurofeedback system, Whitematter facilitates
            the development of <strong>Self-Directed Neuroplasticity</strong> by integrating
            <strong> occupational health guidelines</strong> into its feedback system. We empower
            employers with <strong>data-driven insights</strong> to balance team workloads and
            <strong> enhance performance</strong> within their organization.
          </p>
          <div className="buttons">
            <motion.button
              className="blue-button1 neon-button"
              onClick={handleLearnMoreClick}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Learn More
            </motion.button>
            <motion.button
              className="blue-button neon-button"
              onClick={handleTryForFreeClick}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Take The Survey
            </motion.button>
          </div>
        </motion.div>

        {/* Right Content: slide in from right + fade */}
        <motion.div
          className="right-content"
          initial={{ x: 60, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.9, ease: 'easeOut', delay: 0.2 }}
        >
          <img src={Whitematterimage} className="side-image" alt="Related Graphic" />
        </motion.div>
      </motion.div>
    </main>
  );
}
// Move Header/Footer conditional logic into Layout component
function Layout({ children }) {
  const location = useLocation();  // useLocation inside Router context

  // Paths where you don't want the Header and Footer
  const hideHeaderFooterRoutes = ['/signup', '/signin','/otp'];

  return (
    <>
      {/* Conditionally render Header */}
      {!hideHeaderFooterRoutes.includes(location.pathname) && <Header />}
      
      {/* Render the main content */}
      {children}

      {/* Conditionally render Footer */}
      {!hideHeaderFooterRoutes.includes(location.pathname) && <Footer />}
    </>
  );
}


function App() {
  return (
    <Router>
      <div className="App">
        <Layout>
          <CookiePopup/>
          <Routes>
            <Route 
              path="/" 
              element={
                <>
                  <Home />  {/* Home component with useNavigate logic */}
                  <LogoSection />
                  <VideoSection />
                  <FeatureSection />
                 
                  <FeatureFamily />
                  <Newsletter/>
                </>
              } 
            />
            
            {/* Route for the Products page */}
            <Route path="/products" element={<Products />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<Sign />} />
            <Route path="/otp" element={<OtpVerification/>} />
            <Route path="/account" element={<Account/>} />
            <Route path="/success" element={<Success/>} />
            <Route path="/websiteform" element={<WebsiteForm/>} />
            <Route path="/cancel" element={<Cancel/>} />
            <Route path="/privacy" element={<PrivacyPolicy/>} />
            <Route path="/download" element={<DownloadPage/>} />
            <Route path="/TrustMark" element={<Trustmark/>} />
            <Route path="/Hrconsulting" element={<HRConsulting/>} />
            <Route path="/TrustMark-pricing" element={<TrustmarkPricing/>} />
            <Route path="/cybersecurity" element={<CyberSecurity/>} />
            <Route path="/partnerportal" element={<PartnerPortal/>} />
          </Routes>
        </Layout>
      </div>
    </Router>
  );
}

export default App;
