import React from 'react';
import { Helmet } from 'react-helmet';

const Cancel = () => {
  return (
    <div>
      <Helmet>
        <title>Payment Canceled - Your App Name</title>
        <meta
          name="description"
          content="Your payment was canceled. You can try again or contact support for further assistance."
        />
      </Helmet>
      <h2>Payment was canceled.</h2>
      <p>Your payment was canceled. You can try again or contact support.</p>
    </div>
  );
};

export default Cancel;
