import React from 'react';
import './PartnerPortal.css';
import Partner from './partner.jpg'
const PartnerPortal = () => {
  const scrollToSection = () => {
    const element = document.getElementById('target-section');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const goToPricing = () => {
    window.location.href = '/pricing';
  };

  return (
    <>
      <section className="hero-section-partner tech-bg">
        <div className="hero-content">
          <h1 className="hero-title">Erasys Partner Portal</h1>
          <p className="hero-subtitle">
            <span className="gradient-text2">Partner</span> with Erasys to boost your business and offer services that exceeds customer expectations and drives growth utilizing our innovative software solutions.
          </p>
          <div className="cta-buttons">
            <button className="cta-button learn-more" onClick={scrollToSection}>
              Learn More
            </button>
            
          </div>
        </div>
      </section>

      {/* Partnership Types Section */}
      <section className="partnership-section">
        <div className="partnership-container">
          <div className="partnership-image">
            <img src={Partner} alt="Partner Illustration" />
          </div>
          <div className="partnership-text">
            <h2>Become an Erasys Partner</h2>
            <p>
              Whether you're a <strong>Reseller Partner</strong> looking to expand your product offering or a <strong>Technical Collaboration Partner</strong> aiming to integrate and innovate with Erasys, we’re here to help you succeed.
            </p>
            <p>
              Join our growing network and gain access to exclusive resources, co-marketing opportunities, and cutting-edge solutions.
            </p>
          </div>
        </div>
      </section>

      <section className="why-partner-section"id="target-section">
  <h2 className="why-partner-title">Why partner with Erasys?</h2>
  <p className="why-partner-description">
    Erasys empowers partners with innovative tools, trusted collaboration models, and revenue-driving solutions that elevate your business and customer value.
  </p>

  <div className="why-partner-grid">
    <div className="partner-benefit">
      <div className="partner-icon">
        <svg width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#196deb" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round">
          <path d="M9 18h6" />
          <path d="M10 22h4" />
          <path d="M12 2a7 7 0 0 0-4 12c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2 7 7 0 0 0-4-12z" />
        </svg>
      </div>
      <h3>Be Innovative</h3>
      <p>
        Deliver modern digital solutions using Erasys' powerful technologies, APIs, and automation frameworks.
      </p>
    </div>

    <div className="partner-benefit">
      <div className="partner-icon">
        <svg width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#196deb" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="12" cy="12" r="10" />
          <path d="M12 6v12" />
          <path d="M8 10c0-1.5 1.5-2 4-2s4 .5 4 2-1.5 2-4 2-4 .5-4 2 1.5 2 4 2 4-.5 4-2" />
        </svg>
      </div>
      <h3>Boost Profitability</h3>
      <p>
        Expand your revenue streams with flexible partnership models and access to Erasys’ growing customer base.
      </p>
    </div>

    <div className="partner-benefit">
  <div className="partner-icon">
    {/* Users Icon (Collaboration) */}
    <svg width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#196deb" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round">
      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
      <circle cx="9" cy="7" r="4" />
      <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
      <path d="M16 3.13a4 4 0 0 1 0 7.75" />
    </svg>
  </div>
  <h3>Strengthen Collaboration</h3>
  <p>
    Build long-term partnerships with dedicated Erasys support, joint development, and shared success opportunities.
  </p>
</div>
  </div>
</section>
<section className="join-ecosystem-section">
  <div className="ecosystem-content">
    <h2 className="ecosystem-title">Join our Partner Ecosystem</h2>
    <p className="ecosystem-description">
      Partner with Erasys to solve your customers’ most pressing digital challenges. Our ecosystem combines intelligent solutions, technical innovation, and a global-first approach. Together, we position you as a strategic advisor backed by cutting-edge tools and enterprise-grade infrastructure.
    </p>
    <button className="ecosystem-button" onClick={() => window.location.href = '/contact'}>
      Contact Us
    </button>
  </div>
</section>


    </>
  );
};

export default PartnerPortal;
