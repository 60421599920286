import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './WebsiteForm.css'
const WebsiteForm = () => {
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Function to update the subscription with the provided skuId
  const updateSubscription = async (skuId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/update-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ skuId }),
      });
      if (!response.ok) {
        const data = await response.json();
        console.error('Error updating subscription:', data.message);
        return false;
      } else {
        console.log('Subscription updated successfully.');
        return true;
      }
    } catch (error) {
      console.error('Error updating subscription:', error);
      return false;
    }
  };

  // useEffect to check for a session_id and process the successful payment logic
  useEffect(() => {
    const processPaymentSuccess = async () => {
      const params = new URLSearchParams(location.search);
      const sessionId = params.get('session_id');

      if (!sessionId) {
        return; // No session_id means no payment success to process.
      }

      setLoading(true);
      try {
        // Call the checkout-session endpoint to retrieve session details (including skuId)
        const token = localStorage.getItem('token');
        const response = await fetch('/api/checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include',
          body: JSON.stringify({ sessionId }),
        });
        const data = await response.json();

        if (!response.ok) {
          setError(`Error retrieving checkout session: ${data.message}`);
        } else {
          // Payment succeeded; update subscription using the skuId returned from the session.
          const skuId = data.skuId;
          const updated = await updateSubscription(skuId);
          if (!updated) {
            setError('Subscription update failed.');
          } else {
            console.log('Payment processed and subscription updated.');
          }
        }
      } catch (err) {
        console.error('Error processing payment success:', err);
        setError('An error occurred while processing your payment.');
      } finally {
        setLoading(false);
      }
    };

    processPaymentSuccess();
  }, [location]);

  // Handle submission of the website URL
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/update-website', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ websiteUrl }),
      });
      if (!response.ok) {
        const data = await response.json();
        setError(data.message || 'An error occurred.');
      } else {
        navigate('/website-success'); // Redirect on successful update
      }
    } catch (err) {
      setError('An error occurred while updating your website.');
    }
  };

  return (
    <div className="website-form-container">
      <h2>Enter Your Website URL</h2>
      {loading && <p>Processing your payment...</p>}
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="url"
          placeholder="https://yourwebsite.com"
          value={websiteUrl}
          onChange={(e) => setWebsiteUrl(e.target.value)}
          required
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default WebsiteForm;
