import React, { useRef, useEffect } from 'react';
import './cybersecurity.css';
import Cyber from './cyber.jpg';
import security from './datasecurity.jpg'

const CyberSecurity = () => {
  // Scroll function: scrolls to an element with id "target-section"
  const scrollToSection = () => {
    const section = document.getElementById('target-section');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Redirection function: navigates to a contact page
  const goToContact = () => {
    window.location.href = '/contact';
  };

  const goToTrustmark = () => {
    window.location.href = '/trustmark';
  };


  // Ref for the overview text container for the fade-in animation.
  const overviewTextRef = useRef(null);
  // Ref for service cards for animation.
  const serviceRefs = useRef([]);
  // Ref for the enhanced human risk management section text.
  const riskMgmtTextRef = useRef(null);
  // Ref for the enhanced human risk management section image.
  const riskMgmtImageRef = useRef(null);

  // Observer for the overview text fade-in.
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) entry.target.classList.add('in-view');
        });
      },
      { threshold: 0.5 }
    );
    if (overviewTextRef.current) observer.observe(overviewTextRef.current);
    return () => {
      if (overviewTextRef.current) observer.unobserve(overviewTextRef.current);
    };
  }, []);

  // Observer for each service card fade-in.
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) entry.target.classList.add('in-view');
        });
      },
      { threshold: 0.3 }
    );
    serviceRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });
    return () => {
      serviceRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  // Observer for the Enhanced Human Risk Management section.
  useEffect(() => {
    const elements = [riskMgmtTextRef.current, riskMgmtImageRef.current];
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) entry.target.classList.add('in-view');
        });
      },
      { threshold: 0.5 }
    );
    elements.forEach((el) => {
      if (el) observer.observe(el);
    });
    return () => {
      elements.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  // Array of services related to human factors in cybersecurity.
  const services = [
    {
      title: 'Human Risk Assessment',
      description: 'Evaluate and identify human vulnerabilities that could lead to cyber threats.'
    },
    {
      title: 'Employee Awareness Training',
      description: 'Empower your team with practical, hands-on cybersecurity practices.'
    },
    {
      title: 'Insider Threat Mitigation',
      description: 'Develop strategies to prevent, detect, and respond to insider risks.'
    },
    {
      title: 'Behavioral Analytics',
      description: 'Analyze employee behavior to proactively spot potential security issues.'
    },
    {
      title: 'Culture & Policy Development',
      description: 'Foster a security-first mindset with tailored policies and culture change.'
    }
  ];

  return (
    <>
      {/* Hero Section */}
      <section className="hero-section-cyber tech-bg">
        <div className="hero-content">
          <h1 className="hero-title">Human-Centric Cyber Security</h1>
          <p className="hero-subtitle">
            <span className="gradient-text2">Securing the Human Element:</span> Manage Risks & Empower Your Team
          </p>
          <div className="cta-buttons">
            <button className="cta-button learn-more" onClick={scrollToSection}>
              Learn More
            </button>
            <button className="cta-button buy-now" onClick={goToContact}>
              Contact Us
            </button>
          </div>
        </div>
      </section>

      {/* Overview Section */}
      <section className="overview-section" id="target-section">
        <div className="overview-container">
          {/* Image on the left */}
          <div className="overview-image">
            <img src={Cyber} alt="Cybersecurity Human Factors" />
          </div>
          {/* Text content on the right */}
          <div className="overview-text" ref={overviewTextRef}>
            <h2>Our Human-Centric Approach</h2>
            <p>
              We integrate human factors into every layer of your cybersecurity strategy.
              Our tailored approach helps organizations reduce risks by educating employees
              and building a resilient security culture.
            </p>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="services-section">
        <div className="services-container">
          <h2 className="services-title">Our Services</h2>
          <div className="services-grid">
            {services.map((service, index) => (
              <div
                key={index}
                className="service-card"
                ref={(el) => (serviceRefs.current[index] = el)}
              >
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Enhanced Human Risk Management Section */}
      <section className="risk-management-section">
        <div className="risk-management-container">
          {/* Text on the left */}
          <div className="risk-management-text" ref={riskMgmtTextRef}>
            <h2>Enhanced Human Risk Management</h2>
            <p>
              Our cybersecurity consulting goes beyond traditional measures by integrating
              advanced verification technologies into your security framework. By addressing
              human vulnerabilities, we ensure that only genuine users gain access, while
              non-intrusive, intelligent challenges quietly screen out potential threats.
            </p>
            <p>
              This holistic approach minimizes user friction, improves overall experience,
              and strengthens your organization's defense against social engineering and insider risks.
            </p>
            <button className="cta-button-2" onClick={goToTrustmark}>
              Learn How
            </button>
          </div>
          {/* Image on the right */}
          <div className="risk-management-image" ref={riskMgmtImageRef}>
            <img src={security} alt="Enhanced Human Risk Management" />
          </div>
        </div>
      </section>

      <section className="pricing-section">
        <div className="pricing-content">
          <h2 className="section-title">Pricing</h2>
          <p>
            Our pricing is customized to the needs of your organization. Please contact us for a personalized quote.
          </p>
          <button className="cta-button contact-button" onClick={goToContact}>
            Contact Us
          </button>
        </div>
      </section>
    </>
  );
};

export default CyberSecurity;
