import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Success.css'; // Import the CSS file for styling

const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleSuccess = async () => {
      const params = new URLSearchParams(location.search);
      const sessionId = params.get('session_id');

      if (!sessionId) {
        setError('No session ID found in URL.');
        setLoading(false);
        return;
      }

      try {
        const token = localStorage.getItem('token');
        const response = await fetch('/api/checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sessionId }),
        });

        const data = await response.json();

        if (response.ok) {
          // Payment was successful, update subscription with skuId
          await updateSubscription(data.skuId);
          navigate('/'); // Adjust the path as needed
        } else {
          setError(`Error retrieving checkout session: ${data.message}`);
        }
      } catch (error) {
        console.error('Error handling success:', error);
        setError('An error occurred while processing your payment.');
      } finally {
        setLoading(false);
      }
    };

    handleSuccess();
  }, [location, navigate]);

  const updateSubscription = async (skuId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/update-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ skuId }),
      });
      if (!response.ok) {
        const data = await response.json();
        console.error('Error updating subscription:', data.message);
      } else {
        console.log('Subscription updated successfully.');
      }
    } catch (error) {
      console.error('Error updating subscription:', error);
    }
  };

  return (
    <div className="success-container">
      {/* Helmet for SEO */}
      <Helmet>
        <title>Payment Successful - Erasys</title>
        <meta
          name="description"
          content="Your payment was processed successfully. Thank you for your purchase. Redirecting you to your dashboard."
        />
      </Helmet>

      {loading ? (
        <div className="loading">
          <div className="spinner"></div>
          <h2>Processing your payment...</h2>
        </div>
      ) : error ? (
        <div className="error-container">
          <h2>Payment Error</h2>
          <p>{error}</p>
          <button onClick={() => navigate('/')}>Go Back</button>
        </div>
      ) : (
        <div className="success-message">
          <div className="checkmark">&#10003;</div>
          <h2>Payment Successful!</h2>
          <p>Your payment was processed successfully. Redirecting to your dashboard...</p>
        </div>
      )}
    </div>
  );
};

export default Success;
