import React, { useState, useEffect } from 'react';
import './TrustmarkPricing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faGift, faFire } from '@fortawesome/free-solid-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import { Helmet } from 'react-helmet';

// Define SKU constants
const SKU_IDS = {
  BASIC: 'BASIC_PLAN',
  BUSINESS: 'BUSINESS_PLAN',
  ENTERPRISE: 'ENTERPRISE_PLAN'
};

const TrustmarkPricing = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStripeKey = async () => {
      try {
        const response = await fetch('/config/stripe');
        if (!response.ok) throw new Error('Failed to fetch Stripe publishable key');
        const { publishableKey } = await response.json();
        const stripe = await loadStripe(publishableKey);
        setStripePromise(stripe);
      } catch (error) {
        console.error('Error fetching Stripe publishable key:', error);
      }
    };
    fetchStripeKey();
  }, []);

  const checkAuthenticated = async () => {
    try {
      const resp = await fetch('/api/profile', {
        method: 'GET',
        credentials: 'include',
      });
      return resp.ok;
    } catch (err) {
      console.error('Error checking authentication:', err);
      return false;
    }
  };

  // New function to check for an active subscription
  const checkActiveSubscription = async (skuId) => {
    try {
      const response = await fetch(`/api/check-active-subscription?skuId=${skuId}`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      if (!response.ok || data.active) {
        // If the response is not OK or an active subscription exists,
        // display an error message.
        setErrorMessage(data.message || 'Active subscription exists.');
        setIsErrorModalOpen(true);
        return false;
      }
      return true;
    } catch (error) {
      console.error('Error checking active subscription:', error);
      setErrorMessage('An error occurred while checking your subscription.');
      setIsErrorModalOpen(true);
      return false;
    }
  };
  
  // Handler for Basic Plan using BASIC_PLAN SKU
  const handleBasicSelect = async () => {
    const authenticated = await checkAuthenticated();
    if (!authenticated) {
      navigate('/signin', { state: { target: '/pricing', sku: SKU_IDS.BASIC } });
      return;
    }
    // Pass the BASIC_PLAN SKU to check for an active subscription
    const noActiveSubscription = await checkActiveSubscription(SKU_IDS.BASIC);
    if (!noActiveSubscription) return;
    
    // Redirect to checkout for the paid Basic plan:
    redirectToCheckout(SKU_IDS.BASIC);
  };
  

  // Handler for Business Plan using BUSINESS_PLAN SKU
  const handleBusinessSelect = async () => {
    const authenticated = await checkAuthenticated();
    if (!authenticated) {
      navigate('/signin', { state: { target: '/pricing', sku: SKU_IDS.BUSINESS } });
      return;
    }
    // Check for an active subscription before proceeding
    const noActiveSubscription = await checkActiveSubscription(SKU_IDS.BUSINESS);
    if (!noActiveSubscription) return;
    
    // Redirect to checkout for the paid Basic plan:
    redirectToCheckout(SKU_IDS.BUSINESS);
  };

  // Handler for Enterprise Plan using ENTERPRISE_PLAN SKU
  // If enterprise subscriptions are handled online, you can call redirectToCheckout(SKU_IDS.ENTERPRISE)
  // For this example, we'll continue to navigate to a contact page.
  const handleEnterpriseSelect = async () => {
    const authenticated = await checkAuthenticated();
    if (!authenticated) {
      navigate('/signin', { state: { target: '/pricing', sku: SKU_IDS.ENTERPRISE } });
      return;
    }
    navigate('/contact');
  };

  const updateSubscription = async (skuId) => {
    try {
      const response = await fetch('/api/update-subscription', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ skuId }),
      });

      if (!response.ok) {
        setErrorMessage('Error updating subscription.');
        setIsErrorModalOpen(true);
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error('Error updating subscription:', error);
      setErrorMessage('An error occurred while updating your subscription.');
      setIsErrorModalOpen(true);
      return false;
    }
  };

  const redirectToCheckout = async (skuId) => {
    try {
      if (!stripePromise) return;
      const stripe = await stripePromise;
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ skuId }),
      });

      if (!response.ok) {
        setErrorMessage('Error creating checkout session.');
        setIsErrorModalOpen(true);
        return;
      }
      const session = await response.json();
      await stripe.redirectToCheckout({ sessionId: session.id });
    } catch (error) {
      console.error('Error redirecting to checkout:', error);
    }
  };

  const handleCloseErrorModal = () => setIsErrorModalOpen(false);
  const handleCloseSuccessModal = () => setIsSuccessModalOpen(false);

  return (
    <div className="pricing-page">
      <Helmet>
        <title>TrustMark Pricing Plans</title>
        <meta
          name="description"
          content="Choose the right plan for you—Basic, Business, or Enterprise— with TrustMark security solutions."
        />
      </Helmet>

      <header className="pricing-header">
        <h1>TrustMark Pricing Plans</h1>
        <p>Choose the best security plan for your needs</p>
      </header>

      <div className="pricing-container">
        <div className="pricing-card trial">
          <h2>Basic Plan</h2>
          <p className="price">$25<span>/month</span></p>
          <ul className="features">
            <li>10,000 verifications per month</li>
            <li>Basic bot protection with shape-drawing captcha</li>
            <li>Limited analytics (success/failure rates)</li>
            <li>Community support via forums and documentation</li>
          </ul>
          <button className="btn-pricing" onClick={handleBasicSelect}>
            Get Started
          </button>
        </div>

        <div className="pricing-card standard">
          <h2>Business Plan</h2>
          
          <p className="price">$99<span>/month</span></p>
          <ul className="features">
            <li>100,000 verifications per month</li>
            <li>Advanced bot protection with AI-driven threat detection</li>
            <li>Enhanced analytics (user behaviour, bot attempts, and success rates)</li>
            <li>Priority email support with a 24-hour response time</li>
          </ul>
          <button className="btn-pricing" onClick={handleBusinessSelect}>
            Get Started
          </button>
        </div>

        <div className="pricing-card enterprise">
          <h2>Enterprise Plan</h2>
          <ul className="features">
            <li>Unlimited verifications with scalable infrastructure</li>
            <li>Enterprise support</li>
            <li>Enterprise-grade security with real-time threat intelligence and custom rules</li>
            <li>Comprehensive analytics dashboard with API access for integration</li>
            <li>Dedicated account manager and 24/7 premium support</li>
          </ul>
          <button className="btn-pricing" onClick={handleEnterpriseSelect}>
            Contact Sales
          </button>
        </div>
      </div>

      {isErrorModalOpen && (
        <div className="modal-error">
          <div className="error-modal">
            <h3>Error</h3>
            <p>{errorMessage}</p>
            <button className="btn" onClick={handleCloseErrorModal}>OK</button>
          </div>
        </div>
      )}

      {isSuccessModalOpen && (
        <div className="modal-success">
          <div className="success-modal">
            <h3>Success!</h3>
            <p>Check your email for registration details.</p>
            <button className="btn" onClick={handleCloseSuccessModal}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrustmarkPricing;
