import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Account.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';

const Account = () => {
  const [activeTab, setActiveTab] = useState('subscription');
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
  });
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showMacDropdown, setShowMacDropdown] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [expandedSubscription, setExpandedSubscription] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (activeTab === 'profile') {
      fetchProfile();
    } else if (activeTab === 'subscription') {
      fetchSubscription();
    }
    // Reset messages when switching tabs
    setError('');
    setMessage('');
  }, [activeTab]);

  const fetchProfile = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch('/api/profile', {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      } else if (response.status === 401) {
        navigate('/signin');
      } else {
        setError('Error fetching profile data.');
      }
    } catch (error) {
      setError('Error fetching profile data.');
    } finally {
      setLoading(false);
    }
  };

  const fetchSubscription = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch('/api/subscription', {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        // Expecting an array of subscriptions
        setSubscriptionData(data);
      } else if (response.status === 401) {
        navigate('/signin');
      } else {
        setError('Error fetching subscription data.');
      }
    } catch (error) {
      setError('Error fetching subscription data.');
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setLoading(true);
    setError('');
    setMessage('');
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    setError('');
    setMessage('');
    try {
      const response = await fetch('/api/profile', {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: userData.firstName,
          lastName: userData.lastName,
        }),
      });
      if (response.ok) {
        setMessage('Profile updated successfully.');
      } else {
        setError('Failed to update profile.');
      }
    } catch (error) {
      setError('An error occurred while updating your profile.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleRedirectToPricing = () => {
    navigate('/pricing');
  };

  const handleResetPassword = async () => {
    setIsSubmitting(true);
    setError('');
    setMessage('');
    try {
      const response = await fetch('/api/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: userData.email }),
      });
      if (response.ok) {
        setShowCodeModal(true);
        setMessage('Reset code sent to your email.');
      } else {
        setError('Failed to send reset code.');
      }
    } catch (error) {
      setError('Error sending reset password request.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVerifyCode = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch('/api/verify-reset-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: userData.email, resetCode }),
      });
      if (response.ok) {
        setShowCodeModal(false);
        setShowPasswordModal(true);
        setError('');
        setMessage('');
      } else {
        setError('Invalid reset code.');
      }
    } catch (error) {
      setError('Error verifying reset code.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitNewPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      setMessage('');
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await fetch('/api/reset-password-complete', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: userData.email,
          newPassword,
        }),
      });
      if (response.ok) {
        setShowPasswordModal(false);
        setMessage('Password reset successful.');
        setError('');
      } else {
        setError('Failed to reset password.');
      }
    } catch (error) {
      setError('Failed to reset password.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getSubscriptionName = (skuId) => {
    const mapping = {
      'STD_Mac01': 'WhiteMatter Standard MacOS',
      'STD_Win01': 'WhiteMatter Standard Windows',
      'Free_Mac01': 'WhiteMatter Free Trial MacOS',
      'Free_Win01': 'WhiteMatter Free Trial Windows',
      'BASIC_PLAN': 'TrustMark Basic Plan',
      'BUSINESS_PLAN': 'TrustMark Business Plan',
      'ENTERPRISE_PLAN': 'TrustMark Enterprise Plan',
    };
    return mapping[skuId] || 'Unknown Subscription';
  };

  const getPlanType = (skuId) => {
    if (skuId && skuId.startsWith('STD')) {
      return 'Standard';
    } else if (skuId && skuId.startsWith('Free')) {
      return 'Free Trial';
    } else if (skuId && skuId.startsWith('BASIC')) {
      return 'Basic Plan';
    } else if (skuId && skuId.startsWith('BUSINESS')) {
      return 'Business Plan';
    } else if (skuId && skuId.startsWith('ENTERPRISE')) {
      return 'Enterprise Plan';
    }
    return 'Unknown Plan';
  };

  // Helper to determine if the subscription is a WhiteMatter plan
  const isWhiteMatterSubscription = (skuId) => {
    return ['STD_Mac01', 'STD_Win01', 'Free_Mac01', 'Free_Win01'].includes(skuId);
  };

  // Download function for WhiteMatter subscriptions.
  // It renders download buttons based on the plan type.
  const handleDownloadOrPricingRedirect = (platform, arch = null) => {
    // Check if the subscription is WhiteMatter. Here, we assume the current subscription's skuID is valid.
    // This function will be called within a specific subscription container.
    let fileUrl = '';
    // For macOS options
    if (platform === 'macos') {
      if (arch === 'arm64') {
        fileUrl =
          getPlanType(currentSubscription.skuID) === 'Standard'
            ? 'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter-MacOs-Arm64.zip'
            : 'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter-MacOs-FreeTrial-Arm64.zip';
      } else if (arch === 'intel') {
        fileUrl =
          getPlanType(currentSubscription.skuID) === 'Standard'
            ? 'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter_MacOs-Intel.zip'
            : 'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter-MacOs-FreeTrial-Intel.zip';
      } else {
        fileUrl =
          getPlanType(currentSubscription.skuID) === 'Standard'
            ? 'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter-MacOS.zip'
            : 'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter-MacOS-FreeTrial.zip';
      }
    } else if (platform === 'windows') {
      fileUrl =
        getPlanType(currentSubscription.skuID) === 'Standard'
          ? 'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter.zip'
          : 'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter-FreeTrial.zip';
    }
    // Create a temporary link to trigger the download.
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // For the download function to work inside a subscription container,
  // we'll set a variable for the current subscription being rendered.
  // (This variable is used only inside the mapping callback below.)
  let currentSubscription = {};

  const toggleMacDropdown = () => {
    setShowMacDropdown((prevState) => !prevState);
  };

  // Toggle subscription accordion
  const toggleSubscription = (subscriptionId) => {
    if (expandedSubscription === subscriptionId) {
      setExpandedSubscription(null);
    } else {
      setExpandedSubscription(subscriptionId);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.getElementById('mac-dropdown-menu');
      const button = document.getElementById('macos-button');
      if (dropdown && !dropdown.contains(event.target) && button && !button.contains(event.target)) {
        setShowMacDropdown(false);
      }
    };

    if (showMacDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMacDropdown]);

  return (
    <div className="account-container">
      <Helmet>
        <title>Account - Erasys</title>
        <meta name="description" content="Manage your profile and subscription details on your Account page." />
      </Helmet>

      <div className="sidebar-1">
        <ul>
          <li className={activeTab === 'profile' ? 'active' : ''} onClick={() => handleTabClick('profile')}>
            Profile
          </li>
          <li className={activeTab === 'subscription' ? 'active' : ''} onClick={() => handleTabClick('subscription')}>
            Subscription
          </li>
        </ul>
      </div>

      <div className="account-details">
        {activeTab === 'subscription' ? (
          <div className="section profile">
            <h2>Subscription Details</h2>
            {loading ? (
              <div>Loading...</div>
            ) : error ? (
              <div className="error-message">{error}</div>
            ) : !subscriptionData || subscriptionData.length === 0 ? (
              <div>
                <p>You don't have an active subscription.</p>
                <button className="button" onClick={handleRedirectToPricing}>
                  View Pricing Plans
                </button>
              </div>
            ) : (
              subscriptionData.map((sub, index) => {
                // Set the current subscription for use in the download function within this block
                currentSubscription = sub;
                return (
                  <div key={sub.SubscriptionID || index} className="subscription-item">
                    <div className="subscription-header" onClick={() => toggleSubscription(sub.SubscriptionID)}>
                      <span>{getSubscriptionName(sub.skuID)}</span>
                      <button className="toggle-button">
                        {expandedSubscription === sub.SubscriptionID ? '-' : '+'}
                      </button>
                    </div>
                    {expandedSubscription === sub.SubscriptionID && (
                      <div className="subscription-details">
                        <div className="profile-field">
                          <label>Plan Type:</label>
                          <input
                            type="text"
                            value={`${getPlanType(sub.skuID)}: valid till ${new Date(sub.SubscriptionEndTime).toLocaleDateString()}`}
                            disabled
                          />
                        </div>
                        <div className="profile-field">
                          <label>Registration Key:</label>
                          <input type="text" value={sub.SubscriptionID} className="registration-key" disabled />
                        </div>
                        {isWhiteMatterSubscription(sub.skuID) && (
                          <div className="platform-buttons">
                            <p className="download-now-text">Download Now</p>
                            <div className="platform-button-wrapper">
                              <div className="platform-button-container mac-dropdown-container">
                                <button
                                  id="macos-button"
                                  className="platform-button macos-button"
                                  onClick={toggleMacDropdown}
                                >
                                  <FontAwesomeIcon icon={faApple} size="2x" />
                                </button>
                                <p className="platform-text">MacOS</p>
                                {showMacDropdown && (
                                  <div id="mac-dropdown-menu" className="mac-dropdown-menu">
                                    <button
                                      className="mac-dropdown-item"
                                      onClick={() => {
                                        handleDownloadOrPricingRedirect('macos', 'arm64');
                                        setShowMacDropdown(false);
                                      }}
                                    >
                                      ARM64 (M1/M2)
                                    </button>
                                    <button
                                      className="mac-dropdown-item"
                                      onClick={() => {
                                        handleDownloadOrPricingRedirect('macos', 'intel');
                                        setShowMacDropdown(false);
                                      }}
                                    >
                                      Intel
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className="platform-button-container">
                                <button
                                  className="platform-button windows-button"
                                  onClick={() => handleDownloadOrPricingRedirect('windows')}
                                >
                                  <FontAwesomeIcon icon={faWindows} size="2x" />
                                </button>
                                <p className="platform-text">Windows</p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        ) : (
          <div className="section profile">
            <h2>Profile</h2>
            {loading ? (
              <div>Loading...</div>
            ) : (
              <>
                {error && <div className="error-message-1">{error}</div>}
                {message && <div className="success-message-1">{message}</div>}
                <div className="profile-field">
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={userData.firstName}
                    onChange={(e) => setUserData((prev) => ({ ...prev, firstName: e.target.value }))}
                  />
                </div>
                <div className="profile-field">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="lastName"
                    value={userData.lastName}
                    onChange={(e) => setUserData((prev) => ({ ...prev, lastName: e.target.value }))}
                  />
                </div>
                <div className="profile-field">
                  <label>Email:</label>
                  <input type="email" value={userData.email} disabled />
                </div>
                <div className="profile-field">
                  <label>Company:</label>
                  <input type="text" value={userData.company || 'N/A'} disabled />
                </div>
                <div className="button-container">
                  <button className="button save" onClick={handleSaveChanges} disabled={isSaving}>
                    {isSaving ? 'Saving...' : 'Save'}
                  </button>
                  <button className="button reset" onClick={handleResetPassword} disabled={isSubmitting}>
                    {isSubmitting ? 'Reset Password...' : 'Reset Password'}
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {showCodeModal && (
        <div className="modal-account">
          <div className="modal-content-account">
            <h2>Enter Reset Code</h2>
            <p>A code has been sent to your email. Enter it below:</p>
            {error && <div className="error-message-1">{error}</div>}
            {message && <div className="success-message-1">{message}</div>}
            <input
              className="input-reset-code"
              type="text"
              placeholder="Enter code"
              value={resetCode}
              onChange={(e) => setResetCode(e.target.value)}
            />
            <button onClick={handleVerifyCode} className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </div>
      )}

      {showPasswordModal && (
        <div className="modal-account">
          <div className="modal-content-account">
            <h2>Change Your Password</h2>
            <p>Enter a new password below to change your password.</p>
            <div className="message-container">
              {error && <div className="error-message-1">{error}</div>}
              {message && <div className="success-message-1">{message}</div>}
            </div>
            <div className="input-group">
              <label>New Password</label>
              <input
                className="input-new-password"
                type="password"
                placeholder="New password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Confirm Password</label>
              <input
                className="input-confirm-password"
                type="password"
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <button onClick={handleSubmitNewPassword} className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Changing password...' : 'Change password'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Account;
