import React, { useRef, useEffect } from 'react';
import './hrconsulting.css';
import HR from './hr.jpg';
import benefits from './benefits.jpg';

const HRConsulting = () => {
  // Scroll function: scrolls to an element with id "target-section"
  const scrollToSection = () => {
    const section = document.getElementById('target-section');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Redirection function: navigates to a pricing or contact page
  const goToContact = () => {
    window.location.href = '/contact';
  };

  // Create a ref for the overview text container for the fade-in animation
  const overviewTextRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          }
        });
      },
      { threshold: 0.5 }
    );
    if (overviewTextRef.current) {
      observer.observe(overviewTextRef.current);
    }
    return () => {
      if (overviewTextRef.current) {
        observer.unobserve(overviewTextRef.current);
      }
    };
  }, []);

  return (
    <>
      {/* Hero Section */}
      <section className="hero-section-hr tech-bg">
        <div className="hero-content">
          <h1 className="hero-title">HR Consulting</h1>
          <p className="hero-subtitle">
            <span className="gradient-text2">Transforming Workspaces:</span> Elevating Mental Health &amp; Productivity
          </p>
          <div className="cta-buttons">
            <button className="cta-button learn-more" onClick={scrollToSection}>
              Learn More
            </button>
            <button className="cta-button buy-now" onClick={goToContact}>
              Contact Us
            </button>
          </div>
        </div>
      </section>

      {/* Overview Section */}
      <section className="overview-section" id="target-section">
        <div className="overview-image">
          <img src={HR} alt="Consulting &amp; Workplace Wellbeing" />
        </div>
        <div className="overview-text" ref={overviewTextRef}>
          <h2>Optimizing Mental Health &amp; Productivity</h2>
          <p>
            Our consulting service integrates cutting-edge technology with strategic guidance to foster a healthier,
            more productive work environment. Leveraging Whitematter’s AI-driven neurofeedback system, we empower organizations
            to enhance workplace wellbeing and drive performance—all without the need for wearables.
          </p>
        </div>
      </section>

      {/* Our Approach Section (Timeline Layout) */}
      <section className="approach-timeline-section">
        <div className="approach-timeline-content">
          <h2 className="section-title">Our Approach </h2>
          <div className="timeline">
            <div className="timeline-item">
              <div className="timeline-icon"></div>
              <div className="timeline-content">
                <h3>Holistic Methodology</h3>
                <p>
                  We integrate occupational health guidelines with data-driven insights to create a balanced, high-performing work environment.
                  Our comprehensive approach ensures every aspect of workplace wellbeing is optimized for both productivity and mental health.
                </p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-icon"></div>
              <div className="timeline-content">
                <h3>Technology Integration</h3>
                <p>
                  Utilizing Whitematter’s advanced AI-driven neurofeedback system, we deliver personalized feedback without the need for wearables.
                  This technology empowers employees to self-optimize, fostering a culture of proactive wellbeing and sustained performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits & Outcomes Section (Image-Inspired Layout) */}
      <div className="benefits-wrapper">
        <section className="benefits-image-section">
          <div className="benefits-image-container">
            <div className="benefits-illustration">
              <img src={benefits} alt="Benefits Illustration" />
            </div>
            <div className="benefits-details">
              <h2>Benefits &amp; Outcomes</h2>
              <ul className="benefits-list">
                <li>
                  <span className="benefits-number">01</span>
                  Data-driven insights for balanced workloads
                </li>
                <li>
                  <span className="benefits-number">02</span>
                  Boosted productivity and enhanced wellbeing
                </li>
                <li>
                  <span className="benefits-number">03</span>
                  Reduced stress and improved mental health
                </li>
                <li>
                  <span className="benefits-number">04</span>
                  Personalized feedback without wearables
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>

      {/* Pricing Section (Seamless Design) */}
      <section className="pricing-section">
        <div className="pricing-content">
          <h2 className="section-title">Pricing</h2>
          <p>
            Our pricing is customized to the needs of your organization. Please contact us for a personalized quote.
          </p>
          <button className="cta-button contact-button" onClick={goToContact}>
            Contact Us
          </button>
        </div>
      </section>
    </>
  );
};

export default HRConsulting;
