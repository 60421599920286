import React, { useState, useEffect } from 'react';
import './Header.css';
import Logo from './Erasys-logo.png';
import { FaBars, FaTimes, FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';

async function checkLoginStatus() {
  try {
    const response = await fetch('/api/profile', {
      method: 'GET',
      credentials: 'include',
    });
    return response.ok;
  } catch (error) {
    console.error('Error checking login status:', error);
    return false;
  }
}

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProductsMenuOpen, setIsProductsMenuOpen] = useState(false);
  const [isPricingMenuOpen, setIsPricingMenuOpen] = useState(false);
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const loggedIn = await checkLoginStatus();
      setIsLoggedIn(loggedIn);
    })();
  }, []);

  // This effect resets the menu state on route change
  useEffect(() => {
    setIsMenuOpen(false);
    setIsProductsMenuOpen(false);
    setIsPricingMenuOpen(false);
    setIsServicesMenuOpen(false);
  }, [location.pathname]);

  // Toggle function for the hamburger icon
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsProductsMenuOpen(false);
    setIsPricingMenuOpen(false);
    setIsServicesMenuOpen(false);
  };

  // New function to always close the menu
  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsProductsMenuOpen(false);
    setIsPricingMenuOpen(false);
    setIsServicesMenuOpen(false);
  };

  const toggleProductsMenu = (e) => {
    e.stopPropagation();
    setIsProductsMenuOpen((prev) => !prev);
    setIsPricingMenuOpen(false);
  };

  const togglePricingMenu = (e) => {
    e.stopPropagation();
    setIsPricingMenuOpen((prev) => !prev);
    setIsProductsMenuOpen(false);
  };

  const toggleServicesMenu = (e) => {
    e.stopPropagation();
    setIsServicesMenuOpen((prev) => !prev);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout', {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        setIsLoggedIn(false);
        closeMenu();
        navigate('/');
      } else {
        console.error('Logout failed.');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <header className="header">
      <div className="hamburger-icon" onClick={toggleMenu} role="button" tabIndex={0}>
        {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
      </div>

      <div className="logo1">
        <Link to="/" onClick={closeMenu}>
          <img src={Logo} alt="Erasys Logo" />
        </Link>
      </div>

      <nav className={`nav ${isMenuOpen ? 'nav-open' : ''}`}>
        <ul>
          <li className={location.pathname === '/' ? 'active' : ''}>
            <Link to="/" onClick={closeMenu}>Home</Link>
          </li>

          {/* Products Menu */}
          <li 
            className={`dropdown ${isProductsMenuOpen ? 'open' : ''}`} 
            onMouseLeave={() => setIsProductsMenuOpen(false)}
          >
            <button onClick={toggleProductsMenu} className="dropdown-toggle">
              Products
            </button>
            {isProductsMenuOpen && (
              <ul className="submenu">
                <li>
                  <Link to="/products#whitematter" onClick={closeMenu}>
                    WhiteMatter
                  </Link>
                </li>
                <li>
                  <Link to="/trustmark" onClick={closeMenu}>
                    TrustMark
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Services Menu */}
          <li 
            className={`dropdown ${isServicesMenuOpen ? 'open' : ''}`} 
            onMouseLeave={() => setIsServicesMenuOpen(false)}
          >
            <button onClick={toggleServicesMenu} className="dropdown-toggle">
              Services
            </button>
            {isServicesMenuOpen && (
              <ul className="submenu">
                <li>
                  <Link to="/cybersecurity" onClick={closeMenu}>
                    Cybersecurity Consulting
                  </Link>
                </li>
                <li>
                  <Link to="/hrconsulting" onClick={closeMenu}>
                    HR Consulting
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Pricing Menu */}
          <li className={`dropdown ${isPricingMenuOpen ? 'open' : ''}`} 
              onMouseLeave={() => setIsPricingMenuOpen(false)}>
            <button onClick={togglePricingMenu} className="dropdown-toggle">
              Pricing
            </button>
            {isPricingMenuOpen && (
              <ul className="submenu">
                <li>
                  <Link to="/pricing" onClick={closeMenu}>
                    WhiteMatter
                  </Link>
                </li>
                <li>
                  <Link to="/TrustMark-pricing" onClick={closeMenu}>
                    TrustMark
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li className={location.pathname === '/contact' ? 'active' : ''}>
            <Link to="/contact" onClick={closeMenu}>Contact Us</Link>
          </li>

          <li className={location.pathname === '/download' ? 'active' : ''}>
            <Link to="/download" onClick={closeMenu}>Download</Link>
          </li>

          {/* Partner Portal */}
          <li className={location.pathname === '/partnerportal' ? 'active' : ''}>
            <Link to="/partnerportal" onClick={closeMenu}>Partner Portal</Link>
          </li>

          {isLoggedIn && (
            <li className={location.pathname === '/account' ? 'active' : ''}>
              <Link to="/account" onClick={closeMenu}>Account</Link>
            </li>
          )}

          {isMenuOpen && (
            <>
              {isLoggedIn ? (
                <>
                  <li className="mobile-auth-link">
                    <Link to="/account" onClick={closeMenu}>Account</Link>
                  </li>
                  <li className="mobile-auth-link">
                    <button className="logout-button" onClick={handleLogout}>
                      <FaSignOutAlt className="icon" /> Logout
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li className="mobile-auth-link">
                    <Link to="/signin" onClick={closeMenu}>Sign In</Link>
                  </li>
                  <li className="mobile-auth-link">
                    <Link to="/signup" onClick={closeMenu}>Sign Up</Link>
                  </li>
                </>
              )}
            </>
          )}
        </ul>
      </nav>

      <div className="actions">
        
        <div className="desktop-auth-buttons">
          {isLoggedIn ? (
            <button className="logout-button" onClick={handleLogout}>
              <FaSignOutAlt className="icon" /> Logout
            </button>
          ) : (
            <button className="login-button" onClick={() => { closeMenu(); navigate('/signin'); }}>
              <FaSignInAlt className="icon" /> Login
            </button>
          )}
        </div>
      </div>

      {isMenuOpen && <div className="menu-overlay" onClick={closeMenu} />}
    </header>
  );
};

export default Header;
