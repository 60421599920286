import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Newsletter.css';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('');
    setError('');
    try {
      const response = await fetch('/api/newsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        setMessage('Thank you for subscribing!');
        setEmail('');
      } else {
        const data = await response.json();
        setError(data.message || 'Subscription failed. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again later.');
    }
    setIsSubmitting(false);
  };

  return (
    <div className="newsletter-section">
    <div className="newsletter-container">
      <Helmet>
        <title>Subscribe to our Newsletter</title>
        <meta name="description" content="Stay updated with our latest news and offers." />
      </Helmet>

      <div className="newsletter-icon">
        <svg
          width="64"
          height="64"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#0b19ad"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M4 4h16v16H4z" opacity="0.1" />
          <path d="M4 4h16v16H4z" />
          <path d="M4 4l8 8 8-8" />
        </svg>
      </div>

      <h2 className="newsletter-title">Subscribe To Our Newsletter</h2>
      <p className="newsletter-description">
        Join our subscribers list to get the latest news, updates and special offers delivered directly in your inbox.
      </p>

      <form onSubmit={handleSubmit} className="newsletter-form">
        <input
          type="email"
          placeholder="Enter your email"
          className="newsletter-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="newsletter-button" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Subscribe'}
        </button>
      </form>

      {message && <div className="newsletter-success">{message}</div>}
      {error && <div className="newsletter-error">{error}</div>}
    </div>
    </div>
  );
};

export default Newsletter;
