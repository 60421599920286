import React from 'react';
import './trustmark.css'; // Ensure CSS file is properly linked
import { FaCrown, FaSmile, FaShieldAlt, FaPlug, FaUserShield } from 'react-icons/fa'; // Capability icons
import howItWorksImage from './shield.jpg'; // Ensure correct path
import demoGif from './demo.gif'; // Import your GIF file

const Trustmark = () => {
  const scrollToSection = () => {
    const element = document.getElementById('target-section');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const goToPricing = () => {
    window.location.href = '/TrustMark-pricing'; // Redirect to the pricing page
  };

  return (
    <>
      {/* Hero Section */}
      <section className="hero-section-trustmark tech-bg">
        <div className="hero-content">
          <h1 className="hero-title"> Erasys TrustMark</h1>
          <p className="hero-subtitle">
            <span className="gradient-text2">Revolutionize</span> online access control by verifying genuine users and protecting your site from malicious automated threats.
          </p>
          <div className="cta-buttons">
            <button className="cta-button learn-more" onClick={scrollToSection}>
              Learn More
            </button>
            <button className="cta-button buy-now" onClick={goToPricing}>
              Get started
            </button>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section">
        <h2 className="benefits-title">BENEFITS OF TRUSTMARK SECURITY</h2>
        <div className="benefits-container">
          <div className="benefit-item">
            <FaCrown className="benefit-icon" />
            <h3>Enhanced User Experience</h3>
            <p>Eliminate the hassle of complex verifications. Enjoy seamless, user-friendly authentication.</p>
          </div>
          <div className="benefit-item">
            <FaSmile className="benefit-icon" />
            <h3>Privacy Focused</h3>
            <p>Unlike traditional security solutions, TrustMark ensures data privacy without collecting personally identifiable information.</p>
          </div>
          <div className="benefit-item">
            <FaShieldAlt className="benefit-icon" />
            <h3>Advanced Security Integration</h3>
            <p>Seamlessly integrate with security firewalls to block malicious threats in real-time.</p>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works" id="target-section">
        <div className="how-it-works-container">
          <div className="how-it-works-image">
            <img src={howItWorksImage} alt="How It Works" />
          </div>
          <div className="how-it-works-text">
            <h3 className="how-it-works-title">HOW IT WORKS</h3>
            <h2 className="how-it-works-heading">Verify web visitors without CAPTCHAs</h2>
            <p className="how-it-works-description">
              TrustMark Security can be easily embedded into any website — without interrupting user experience.
            </p>
            <p className="how-it-works-description">
              Our technology generates multiple types of non-intrusive challenges to verify users are human, all without requiring users to solve puzzles.
            </p>
          </div>
        </div>
      </section>

      {/* Capabilities Section */}
      <section className="capabilities-section">
        <h2 className="capabilities-title">Why Choose TrustMark?</h2>
        <p className="capabilities-subtitle">
          A next-generation security solution ensuring seamless protection and effortless integration.
        </p>
        <div className="capabilities-container">
          <div className="capability-item">
            <FaShieldAlt className="capability-icon" />
            <h3>Real-time Protection</h3>
            <p>Continuously monitors and blocks threats, ensuring secure user access 24/7.</p>
          </div>
          <div className="capability-item">
            <FaPlug className="capability-icon" />
            <h3>Seamless Integration</h3>
            <p>Works effortlessly with all major platforms and CMS solutions without complex setup.</p>
          </div>
          <div className="capability-item">
            <FaUserShield className="capability-icon" />
            <h3>Unmatched Excellence</h3>
            <p>Improve user experience by minimizing CAPTCHA frustration and enhancing accessibility while ensuring strong bot detection and security.</p>
          </div>
        </div>
      </section>

      {/* GIF Section */}
      <section className="gif-section">
        <h2 className="gif-title">See It in Action</h2>
        <div className="gif-container">
          <img src={demoGif} alt="Demo GIF" className="demo-gif" />
        </div>
      </section>

      {/* Pricing CTA Section */}
      <section className="pricing-cta">
        <h2 className="pricing-title">Get Started with TrustMark</h2>
        <p className="pricing-subtitle">Choose a plan that best suits your security needs.</p>
        <button className="pricing-button" onClick={goToPricing}>View Pricing Plans</button>
      </section>
    </>
  );
};

export default Trustmark;

//cooprate partner page it should have the following: name,in inquiry type remove parter oppurtinies 